import {
  HStack,
  Icon,
  IconButton,
  Text,
  TextProps,
  useClipboard,
} from "@chakra-ui/react";
import { FaCheck as CheckmarkIcon, FaCopy as CopyIcon } from "react-icons/fa";

export function TextWithCopyToClipboard({
  value,
  label,
  ...props
}: { value: string; label?: string } & TextProps) {
  const { hasCopied, onCopy } = useClipboard(value);

  return (
    <HStack>
      <Text whiteSpace={"nowrap"} {...props}>
        {label ?? value}
      </Text>
      <IconButton
        size={"xs"}
        variant="ghost"
        onClick={onCopy}
        icon={<Icon as={hasCopied ? CheckmarkIcon : CopyIcon} />}
        aria-label="Copy to Clipboard"
      ></IconButton>
    </HStack>
  );
}
