import { Button, CircularProgress, HStack } from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { useChunkedUpload } from "../../chunked-file-upload";
import { Step, StepProps } from "../../components/Stepper";
import { useAddApplicationContext } from "../AddApplicationContext";
import { ApplicationArchiveUpload } from "../components/ApplicationArchiveUpload";

export function UploadApplicationBuildStep({ ...props }: StepProps) {
  const {
    state: {
      applicationBuild: { id: savedApplicationId },
    },
    dispatch,
  } = useAddApplicationContext();
  const uploadState = useChunkedUpload();
  const isUploaded = !!uploadState.result;
  const { number, goToNext } = props;

  const startUploading = useCallback(() => {
    if (uploadState.isUploading) {
      uploadState.abort();
    } else {
      uploadState.start();
      goToNext && goToNext();
    }
  }, [goToNext, uploadState]);

  useEffect(() => {
    // once the result of the upload is known, store it
    dispatch({
      type: "APPLICATION_BUILD_UPLOAD:SUCCESS",
      result: uploadState.result,
    });
  }, [dispatch, uploadState.result]);

  useEffect(() => {
    // when a file has been selected, remember its name in the state
    if (!uploadState.file) {
      dispatch({
        type: "APPLICATION_BUILD_UPLOAD:RESET",
      });
      return;
    }
    dispatch({
      type: "APPLICATION_BUILD_UPLOAD:START",
      filename: uploadState.file.name,
    });
  }, [dispatch, uploadState.file]);

  return (
    <Step
      {...props}
      title={
        uploadState.isUploading
          ? `Uploading application ... (${Math.round(
              uploadState.progress * 100,
            )}%)`
          : "Upload application"
      }
      icon={
        uploadState.isUploading ? (
          <CircularProgress
            size={8}
            value={uploadState.progress * 100}
            color="brand.500"
            bgColor="modal-bg"
          />
        ) : undefined
      }
      actionButtons={
        <HStack>
          {!isUploaded && (
            <Button
              size="sm"
              colorScheme={
                uploadState.isUploading
                  ? "orange"
                  : uploadState.file && !uploadState.error
                    ? "brand"
                    : undefined
              }
              isDisabled={!uploadState.file || !!uploadState.error}
              onClick={startUploading}
            >
              {uploadState.isUploading ? "Abort" : "Start Upload"}
            </Button>
          )}
          {(isUploaded || uploadState.isUploading) && (
            <Button size="sm" onClick={goToNext}>
              Next
            </Button>
          )}
          {!isUploaded && number > 1 && (
            <Button size="sm" onClick={goToNext} variant="outline">
              Skip
            </Button>
          )}
        </HStack>
      }
    >
      <ApplicationArchiveUpload
        isDisabled={!!savedApplicationId}
        uploadState={uploadState}
      />
    </Step>
  );
}
