import {
  Alert,
  AlertIcon,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { DevTool } from "../../hookform-devtools";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { omit } from "remeda";
import { FormSubmitButton } from "../../components";
import { RegionsDropDown } from "../../components/RegionsDropDown";
import { VmSizesDropDown } from "../../components/VmSizesDropDown";
import { useActiveOrganizationQuery } from "../../hooks";
import { useCloudRenderingRegionsQuery } from "../hooks";
import { useCreateVirtualMachineForm } from "../hooks/useCreateVirtualMachineForm";
import { useCreateVirtualMachineMutation } from "../hooks/useCreateVirtualMachineMutation";
import { CreateVirtualMachine } from "./virtualMachineSchema";

export const CreateVirtualMachineModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose(): void;
}) => {
  const {
    formState,
    control,
    watch,
    resetField,
    getValues,
    register,
    handleSubmit,
    setValue,
  } = useCreateVirtualMachineForm();
  const { data: regions } = useCloudRenderingRegionsQuery();
  const createVirtualMachine = useCreateVirtualMachineMutation({});
  const toast = useToast();
  const hours = useMemo(() => Array.from({ length: 12 }, (_, i) => i + 1), []);
  const { data: organization } = useActiveOrganizationQuery();

  useEffect(() => {
    if (!organization?.id) {
      throw Error("Organization not found");
    }
    setValue("organizationId", organization?.id?.toString());
  }, [organization, setValue]);

  const onSubmit = useCallback(
    (values: CreateVirtualMachine) =>
      createVirtualMachine.mutateAsync(values, {
        onSuccess: () => {
          toast({
            title: "Created",
            description: `Created Virtual Machine.`,
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top-right",
          });
          onClose();
        },
        onError: () => {
          toast({
            title: "Error",
            description: `Could not create Virtual Machine. Please try again later.`,
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "top-right",
          });
        },
      }),
    [createVirtualMachine, onClose, toast],
  );

  const selectedRegion = watch("region");
  const allowedVmSizes = useMemo(
    () =>
      regions?.find((r) => r.name === selectedRegion)?.supportedVmSizes ?? [],
    [regions, selectedRegion],
  );

  const [customImageEnabled, setCustomImageEnabled] = useState(
    !!getValues("image"),
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Virtual Machine</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                <FormControl isInvalid={!!formState.errors.region}>
                  <FormLabel>Region</FormLabel>
                  <Controller
                    control={control}
                    name="region"
                    render={({ field: { onChange, ...otherFields } }) => {
                      return (
                        <RegionsDropDown
                          onChange={(change) => {
                            onChange(change?.name);
                          }}
                          {...omit(otherFields, ["ref"])}
                        />
                      );
                    }}
                  />
                  <FormErrorMessage>
                    {formState.errors.region?.message}
                  </FormErrorMessage>

                  <FormHelperText>
                    Cloud rendering region where the resources should be placed.
                    Different cloud rendering regions come with different cloud
                    computing cost.
                  </FormHelperText>
                </FormControl>

                <FormControl isInvalid={!!formState.errors.size}>
                  <FormLabel>Vm Size</FormLabel>
                  <Controller
                    control={control}
                    name="size"
                    render={({ field: fieldProps }) => {
                      return (
                        <VmSizesDropDown
                          allowedVmSizes={allowedVmSizes}
                          {...omit(fieldProps, ["ref"])}
                        />
                      );
                    }}
                  />
                  <FormErrorMessage>
                    {formState.errors.size?.message}
                  </FormErrorMessage>
                  <FormHelperText>
                    Different VM sizes come at a different cost in different
                    regions.
                  </FormHelperText>
                </FormControl>

                <Checkbox
                  isChecked={customImageEnabled}
                  onChange={(c) => {
                    setCustomImageEnabled(c.target.checked);
                    if (!c.target.checked) {
                      resetField("image");
                    }
                  }}
                >
                  <FormLabel margin={0}>Custom image</FormLabel>
                </Checkbox>

                <FormControl isInvalid={!!formState.errors.image}>
                  <Input
                    isDisabled={!customImageEnabled}
                    {...register("image")}
                    placeholder="Custom image"
                  />

                  <FormHelperText>
                    Specify a custom image to use for the virtual machine. If
                    empty the default image will be used.
                  </FormHelperText>
                </FormControl>

                <FormControl isInvalid={!!formState.errors.expirationTimeSpan}>
                  <FormLabel>Expiration</FormLabel>
                  <Controller
                    control={control}
                    name="expirationTimeSpan"
                    render={({ field: fieldProps }) => {
                      return (
                        <Select
                          placeholder="Select time"
                          value={fieldProps.value}
                          onChange={(c) => fieldProps.onChange(c.target.value)}
                        >
                          {hours.map((hour) => (
                            <option
                              key={hour}
                              value={
                                hour.toString().padStart(2, "0") + ":00:00"
                              }
                            >
                              {hour} hour{hour > 1 ? "s" : ""}
                            </option>
                          ))}
                        </Select>
                      );
                    }}
                  />
                  <FormErrorMessage>
                    {formState.errors.expirationTimeSpan?.message}
                  </FormErrorMessage>
                  <FormHelperText>
                    Time until a virtual machine is automatically destroyed.
                  </FormHelperText>
                </FormControl>

                <FormControl isInvalid={!!formState.errors.debugModeEnabled}>
                  <Flex alignItems={"center"}>
                    <FormLabel htmlFor="is_active" mb="0">
                      Enable Debug Mode
                    </FormLabel>
                    <Controller
                      control={control}
                      name="debugModeEnabled"
                      render={({ field }) => (
                        <Switch
                          id="debugModeEnabled"
                          isChecked={field.value ?? false}
                          {...field}
                          value="debugModeEnabled"
                        />
                      )}
                    />
                  </Flex>
                  <FormHelperText>
                    Enable the Debug Mode on the VM from start.
                  </FormHelperText>
                  <FormErrorMessage>
                    {formState.errors.debugModeEnabled?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormSubmitButton alignSelf={"end"} formState={formState} />
                <Alert status="error" hidden={!createVirtualMachine.isError}>
                  <AlertIcon />
                  Could not save the Virtual Machine. Please try again later.
                </Alert>
              </Stack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      <DevTool control={control} placement="bottom-left" />
    </>
  );
};
