import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getVmSizes, VmSizeInformation } from "../../session-management";

export function useVmSizesQuery<T = VmSizeInformation[]>(
  options: Partial<
    UseQueryOptions<VmSizeInformation[], Error, T, QueryKey>
  > = {},
) {
  const result = useQuery({
    queryKey: ["cloud-rendering-vm-sizes"],
    queryFn: getVmSizes,
    staleTime: 1800,
    refetchOnMount: false,
    ...options,
  });
  return result;
}

export function useVmSizeQuery(name: string) {
  return useVmSizesQuery({
    select: (sizes) => sizes.find((x) => x.name === name),
  });
}
