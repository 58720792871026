import {
  Box,
  BoxProps,
  Button,
  FormControl,
  Grid,
  GridItem,
  Heading,
  HStack,
  Select,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { getLocalTimeZone } from "@internationalized/date";
import { DateRange } from "@react-types/datepicker";
import { useIsFetching, useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo } from "react";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";
import { useAuthentication } from "../auth";
import { ErrorBoundary } from "../components";
import {
  DateRangePicker,
  toCalendarDate,
} from "../components/date-range-picker";
import {
  useActiveOrganizationQuery,
  useDateTimeRangeQueryParams,
} from "../hooks";
import { MostActiveUsers } from "./charts/ActiveUsers";
import { CloudRenderedSessionsInTimeSpan } from "./charts/CloudRenderedSessionsInTimeSpan";
import { ConcurrentUsers } from "./charts/ConcurrentUsers";
import { Immersiveness } from "./charts/Immersiveness";
import { NumberOfCloudRenderedMinutes } from "./charts/NumberOfCloudRenderedMinutes";
import { PopularApplications } from "./charts/PopularApplications";
import { RenderingModeComparison } from "./charts/RenderingModeComparison";
import { SessionsTrend } from "./charts/SessionsTrend";
import { TotalApplications } from "./charts/TotalApplicationsUsed";
import { TotalSessionsInTimeSpan } from "./charts/TotalSessionsInTimeSpan";
import { TotalUsers } from "./charts/TotalUsers";
import { TotalUsersWithSessions } from "./charts/TotalUsersWithSessions";
import { TimeSpanProps } from "./charts/types";
import { ExportDataDialog } from "./components/ExportDataDialog";
import { DashboardContext } from "./context";
import { generateDataExport } from "./export";
import { usePreviousTimeSpan } from "./hooks/usePreviousTimeSpan";
import { useUserGroupsQuery } from "./hooks/useUserGroups";

const Card: React.FC<React.PropsWithChildren<BoxProps>> = ({ ...props }) => {
  return (
    <Box
      borderColor={"chakra-border-color"}
      borderRadius={"md"}
      padding={4}
      paddingX={6}
      bg="chakra-body-bg"
      h="100%"
      {...props}
    />
  );
};

export type DataExportProps = {
  url: string;
  filename: string;
};

const maxDate = toCalendarDate(new Date());
const minDate = toCalendarDate(dayjs().subtract(12, "months").toDate());

export function Dashboard() {
  const { data: organization } = useActiveOrganizationQuery();
  const { token } = useAuthentication();
  const [{ fromDate: startDate, toDate: endDate }, setDateTimeRangeRange] =
    useDateTimeRangeQueryParams({
      fromDate: useMemo(
        () => dayjs().subtract(7, "days").startOf("day").toDate(),
        [],
      ),
      toDate: useMemo(() => dayjs().endOf("day").toDate(), []),
    });
  const [selectedUserGroupId, setSelectedUserGroupId] = useQueryParam(
    "group",
    withDefault(NumberParam, undefined),
  );
  const previousTimeSpan = usePreviousTimeSpan(startDate, endDate);
  const { timespanFormatted, previousTimeSpanFormatted } = useMemo(
    () => ({
      timespanFormatted: `${dayjs(startDate).format("L")} - ${dayjs(
        endDate,
      ).format("L")}`,
      previousTimeSpanFormatted: `${dayjs(previousTimeSpan.from).format(
        "L",
      )} - ${dayjs(previousTimeSpan.to).format("L")}`,
    }),
    [startDate, endDate, previousTimeSpan],
  );
  const isFetching = useIsFetching();

  // User groups filter
  const userGroupsQuery = useUserGroupsQuery({
    throwOnError: true,
    retry: false,
  });

  // Select the everyone group per default (once data is loaded)
  useEffect(() => {
    if (userGroupsQuery.isSuccess && !selectedUserGroupId) {
      const everyoneUserGroup = userGroupsQuery.data.results.find(
        (group) => group.name === "Everyone",
      );
      everyoneUserGroup && setSelectedUserGroupId(everyoneUserGroup.id);
    }
  }, [selectedUserGroupId, userGroupsQuery, setSelectedUserGroupId]);

  const exportDataMutation = useMutation<
    DataExportProps,
    unknown,
    TimeSpanProps & { organizationId: string }
  >({
    mutationFn: async ({ from, to, organizationId }) =>
      await generateDataExport(from, to, organizationId),
  });

  const updateDateRange = useCallback(
    (range: DateRange) => {
      if (
        range.start >= minDate &&
        range.end <= maxDate &&
        range.start <= range.end
      ) {
        const end = dayjs(range.end.toDate(getLocalTimeZone()))
          .endOf("day")
          .toDate();
        setDateTimeRangeRange({
          fromDate: range.start.toDate(getLocalTimeZone()),
          toDate: end,
        });
      } else {
        console.error("Invalid date range selected");
      }
    },
    [setDateTimeRangeRange],
  );

  return (
    <>
      <DashboardContext.Provider
        value={{
          from: startDate,
          to: endDate,
          userGroupId: selectedUserGroupId,
        }}
      >
        <Stack spacing={8}>
          <HStack>
            <Heading>Dashboard</Heading>
          </HStack>
          <Stack>
            <Grid templateColumns="repeat(5, 1fr)" gap={2}>
              <GridItem>
                <Text whiteSpace={"nowrap"}>Period: </Text>
              </GridItem>
              <GridItem colSpan={3}>
                <HStack>
                  <DateRangePicker
                    defaultValue={useMemo(
                      () => ({
                        start: toCalendarDate(startDate),
                        end: toCalendarDate(endDate),
                      }),
                      [startDate, endDate],
                    )}
                    minValue={minDate}
                    maxValue={maxDate}
                    onChange={updateDateRange}
                    label="Pick a date range for the report"
                  />
                  <Text
                    fontSize="sm"
                    whiteSpace={"nowrap"}
                    color="chakra-subtle-text"
                  >
                    (Timezone: &quot;{getLocalTimeZone()}&quot;)
                  </Text>
                </HStack>
              </GridItem>
              <GridItem>{isFetching ? <Spinner /> : null}</GridItem>
              <GridItem rowStart={2}>
                <Text whiteSpace={"nowrap"}>User group:</Text>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  width="auto"
                >
                  <Select
                    onChange={(evt) =>
                      setSelectedUserGroupId(parseInt(evt.target.value))
                    }
                    isDisabled={userGroupsQuery.isLoading}
                    bgColor="chakra-body-bg"
                    value={selectedUserGroupId}
                    size="sm"
                  >
                    {userGroupsQuery.data?.results.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem colStart={5}>
                <Button
                  isLoading={exportDataMutation.isPending}
                  isDisabled={!exportDataMutation.isIdle}
                  size="sm"
                  onClick={() => {
                    if (!organization || !token) {
                      // button is disabled as long as these conditions are never met so this should never happen but we need to assert they're defined
                      return;
                    }

                    exportDataMutation.mutate({
                      from: startDate,
                      to: endDate,
                      organizationId: organization.id.toString(),
                    });
                  }}
                >
                  Export data
                </Button>
              </GridItem>
            </Grid>
          </Stack>

          <ErrorBoundary>
            <Stack>
              <Heading as="h3" size="md">
                Activity
              </Heading>
              <Heading
                as="h4"
                size="xs"
                fontWeight="normal"
                color="chakra-subtle-text"
              >
                Within period <b>{timespanFormatted}</b> compared to previous
                period <b>{previousTimeSpanFormatted}</b>
              </Heading>
            </Stack>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                <Card>
                  <TotalSessionsInTimeSpan />
                </Card>
                <Card>
                  <ConcurrentUsers />
                </Card>
              </SimpleGrid>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                <Card>
                  <NumberOfCloudRenderedMinutes />
                </Card>
                <Card>
                  <CloudRenderedSessionsInTimeSpan />
                </Card>
              </SimpleGrid>
              <Card>
                <SessionsTrend />
              </Card>
              <Card>
                <RenderingModeComparison />
              </Card>
            </SimpleGrid>
            <Stack>
              <Heading as="h3" size="md">
                Users
              </Heading>
              <Heading
                as="h4"
                size="xs"
                fontWeight="normal"
                color="chakra-subtle-text"
              >
                Data for period <b>{timespanFormatted}</b>
              </Heading>
            </Stack>
            <Grid templateColumns="repeat(4, 1fr)" gap={4}>
              <GridItem>
                <Card>
                  <TotalUsers />
                </Card>
              </GridItem>
              <GridItem colStart={1} rowStart={2}>
                <Card>
                  <TotalUsersWithSessions />
                </Card>
              </GridItem>
              <GridItem colSpan={2} rowSpan={3}>
                <Card>
                  <MostActiveUsers />
                </Card>
              </GridItem>
            </Grid>
            <Stack>
              <Heading as="h3" size="md">
                Applications
              </Heading>
              <Heading
                as="h4"
                size="xs"
                fontWeight="normal"
                color="chakra-subtle-text"
              >
                Data for period <b>{timespanFormatted}</b>
              </Heading>
            </Stack>
            <Grid templateColumns="repeat(4, 1fr)" gap={4}>
              <GridItem colSpan={3} rowSpan={2}>
                <Card>
                  <PopularApplications />
                </Card>
              </GridItem>
              <GridItem colStart={4}>
                <Card>
                  <Immersiveness />
                </Card>
              </GridItem>
              <GridItem colStart={4}>
                <Card>
                  <TotalApplications />
                </Card>
              </GridItem>
            </Grid>
          </ErrorBoundary>
        </Stack>
      </DashboardContext.Provider>
      <ExportDataDialog exportDataMutation={exportDataMutation} />
    </>
  );
}
