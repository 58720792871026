import { Skeleton } from "@chakra-ui/react";
import { useCloudRenderingRegionQuery } from "../cloud-rendering/hooks/useCloudRenderingRegionsQuery";
import { ReservedInstance } from "../session-management";

export function RegionDisplay({
  region,
}: {
  region: ReservedInstance["region"] | null;
}) {
  const regionInfoQuery = useCloudRenderingRegionQuery(region);

  return (
    <Skeleton isLoaded={regionInfoQuery.isSuccess}>
      {regionInfoQuery?.data?.displayName ?? "Unknown"}
    </Skeleton>
  );
}
