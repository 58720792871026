import { CheckIcon, CopyIcon, Icon, WarningTwoIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  HStack,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Skeleton,
  Spacer,
  Stack,
  Switch,
  Tag,
  Text,
  Tooltip,
  useBoolean,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { useCallback, useEffect, useMemo } from "react";
import { CgDebug as DebugMode } from "react-icons/cg";
import { FaFilter as FilterIcon } from "react-icons/fa";
import { Link as RouterLink, generatePath } from "react-router-dom";
import {
  DeleteButton,
  LazyUserProfile,
  OptionsButton,
  PaginatedTable,
  TextWithCopyToClipboard,
} from "../../components";
import { NoData } from "../../components/NoData";
import { useConfirm } from "../../confirm-dialog";
import { usePaginationQueryParams } from "../../hooks";
import { useIsSuperuser } from "../../hooks/usePermissions";
import { namedRoutes } from "../../routes";
import { ReservedInstance } from "../../session-management";
import {
  VirtualMachine,
  VirtualMachineList,
} from "../../session-management/types";
import { useDestroyVirtualMachineMutation } from "../hooks/useDestroyVirtualMachineMutation";
import { useEnableDebugModeVirtualMachineMutation } from "../hooks/useEnableDebugModeVirtualMachineMutation";
import { useVirtualMachinesQuery } from "../hooks/useVirtualMachinesQuery";
import { CreateVirtualMachineModal } from "./CreateVirtualMachineModal";

dayjs.extend(timezone);

const columnHelper = createColumnHelper<VirtualMachine>();

const VirtualMachinesTableContainer = ({
  virtualMachinesQuery,
  setPagination,
  pagination,
}: {
  virtualMachinesQuery: UseQueryResult<VirtualMachineList, unknown>;
  setPagination: ReturnType<typeof usePaginationQueryParams>[1];
  pagination: ReturnType<typeof usePaginationQueryParams>[0];
}) => {
  const toast = useToast();
  const isSuperuser = useIsSuperuser();

  const { confirm } = useConfirm();
  const { mutateAsync: destroyVirtualMachineAsync } =
    useDestroyVirtualMachineMutation({
      onSuccess: () => {
        toast({
          title: "Destroyed",
          description: `Destroyed Virtual Machine.`,
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      },
      onError: () => {
        toast({
          title: "Error",
          description: `Could not destory Virtual Machine. Please try again later.`,
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      },
    });

  const { mutateAsync: enableVirtualMachineDebugModeAsync } =
    useEnableDebugModeVirtualMachineMutation({
      onSuccess: () => {
        toast({
          title: "Enabled",
          description: `Enabled Debug mode on Virtual Machine.`,
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      },
      onError: () => {
        toast({
          title: "Error",
          description: `Could not enable Debug Mode on Virtual Machine. Please try again later.`,
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      },
    });

  const destroyVirtualMachine = useCallback(
    (virtualMachine: VirtualMachine) =>
      confirm({
        title: `Destroy Virtual Machine`,
        body: (
          <Stack>
            <Text>
              You are about to destroy <i>{virtualMachine.id}</i>.
            </Text>
            <Text>This action cannot be undone!</Text>
          </Stack>
        ),
        confirmButtonText: "Destroy",
        onConfirm: () => destroyVirtualMachineAsync(virtualMachine.id),
      }),
    [confirm, destroyVirtualMachineAsync],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        id: "id",
        header: "ID",
        cell: (info) => (
          <TextWithCopyToClipboard
            label={info.getValue().substring(0, 7) + "..."}
            value={info.getValue().toString()}
            w={14}
          />
        ),
      }),
      columnHelper.accessor("activeUserIdentifier", {
        id: "user",
        header: "User",
        cell: (info) => {
          var value = info.getValue();
          return value ? (
            <RouterLink
              to={generatePath(namedRoutes.user.overview, {
                userId: value.toString(),
              })}
            >
              <LazyUserProfile userId={value} />
            </RouterLink>
          ) : info.row.original.activeUserIdentifier ? (
            `n/a (ID: ${info.row.original.activeUserIdentifier})`
          ) : (
            "n/a"
          );
        },
        enableSorting: false,
      }),
      columnHelper.accessor("isStarted", {
        header: "Ready",
        cell: (props) => (
          <>
            {props.row.original.isReady ? (
              <>
                <CheckIcon /> Ready
              </>
            ) : (
              <>
                <WarningTwoIcon /> Not Ready
              </>
            )}
          </>
        ),
      }),
      columnHelper.display({
        header: "Scope",
        cell: (props) =>
          props.row.original.organizationId ? (
            <Tag>org</Tag>
          ) : (
            <Tag>global</Tag>
          ),
      }),
      columnHelper.accessor("regionDisplayName", {
        header: "Region",
      }),
      columnHelper.accessor("sizeDisplayName", {
        header: "Vm Size",
      }),
      columnHelper.accessor("image", {
        header: "Image",
      }),
      columnHelper.display({
        header: "Public Ip",
        cell: (props) =>
          props.row.original.publicIp ? (
            <TextWithCopyToClipboard
              label={props.row.original.publicIp}
              value={props.row.original.publicIp}
              w={14}
            />
          ) : (
            <>n/a</>
          ),
      }),

      columnHelper.display({
        header: "Type",
        cell: (props) => (
          <>
            {props.row.original.reservedInstanceId ? (
              <Tag>reserved instance</Tag>
            ) : props.row.original.expirationDateTime ? (
              <Tag>expiring vm</Tag>
            ) : (
              <></>
            )}
          </>
        ),
      }),
      columnHelper.display({
        header: "Expiration",
        cell: (props) => (
          <>
            {props.row.original.expirationDateTime ? (
              dayjs
                .utc(props.row.original.expirationDateTime)
                .tz(dayjs.tz.guess())
                .format("HH:mm")
            ) : (
              <i>no expiration</i>
            )}
          </>
        ),
      }),
      columnHelper.display({
        header: "State",
        cell: (props) => (
          <>
            {props.row.original.pulumiState}/{props.row.original.state}
          </>
        ),
      }),
      columnHelper.display({
        id: "options",
        cell: (props) => (
          <Flex justifyContent={"end"}>
            <OptionsButton
              label="Click on this button to display user actions"
              isDisabled={!isSuperuser}
            >
              {props.row.original.pulumiState === "Up" && (
                <Tooltip
                  label={
                    props.row.original.debugModeEnabled
                      ? "Debug Mode already enabled"
                      : "Enable Debug Mode"
                  }
                  aria-label="Enable Debug Mode"
                >
                  <Button
                    variant="ghost"
                    fontWeight="normal"
                    fontSize="sm"
                    w="full"
                    isDisabled={props.row.original.debugModeEnabled}
                    leftIcon={<DebugMode />}
                    onClick={() => {
                      enableVirtualMachineDebugModeAsync(props.row.original.id);
                    }}
                  >
                    Enable Debug Mode
                  </Button>
                </Tooltip>
              )}
              <Button
                variant="ghost"
                fontWeight="normal"
                fontSize="sm"
                w="full"
                leftIcon={<CopyIcon />}
                onClick={() => {
                  navigator.clipboard.writeText(props.row.original.id);
                  toast({
                    title: "Copied",
                    description: `Copied Virtual Machine Id to clipboard.`,
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                    position: "top-right",
                  });
                }}
              >
                Copy Id
              </Button>

              <DeleteButton
                onClick={() => destroyVirtualMachine(props.row.original)}
              >
                Destroy
              </DeleteButton>
            </OptionsButton>
          </Flex>
        ),
        header: () => <Flex justifyContent={"end"}>Options</Flex>,
      }),
    ],
    [
      destroyVirtualMachine,
      enableVirtualMachineDebugModeAsync,
      isSuperuser,
      toast,
    ],
  );

  const table = useReactTable({
    columns: useMemo(
      () =>
        virtualMachinesQuery.isLoading
          ? columns.map((col, idx) => ({
              ...col,
              cell: () => <Skeleton key={idx}>Loading</Skeleton>,
            }))
          : columns,
      [columns, virtualMachinesQuery.isLoading],
    ),
    data: useMemo(
      () =>
        virtualMachinesQuery.isLoading
          ? Array(5).fill({} as ReservedInstance)
          : [...(virtualMachinesQuery.data?.items ?? [])],
      [virtualMachinesQuery.data, virtualMachinesQuery.isLoading],
    ),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
      columnOrder: ["isCurrentlyActive"],
    },
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    manualPagination: true,
    pageCount: virtualMachinesQuery.data?.totalPages,
    autoResetPageIndex: false,
  });

  return table;
};

export const VirtualMachinesTable = () => {
  const toast = useToast();
  const createVirtualMachineDisclosure = useDisclosure();
  const [includeDestroyed, setIncludeDestroyed] = useBoolean(false);
  const [pagination, setPagination] = usePaginationQueryParams();
  const virtualMachinesQuery = useVirtualMachinesQuery({
    includeDestroyed: includeDestroyed,
    page: pagination.pageIndex + 1,
    pageSize: pagination.pageSize,
  });

  useEffect(() => {
    if (virtualMachinesQuery.isError) {
      toast({
        title: "Could not load virtual machines.",
        description: `Could not load virtual machines. Please try again later.`,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [virtualMachinesQuery, toast]);

  const table = VirtualMachinesTableContainer({
    virtualMachinesQuery,
    setPagination,
    pagination,
  });

  return (
    <>
      <Stack>
        <HStack>
          <Text>
            This shows a list of Virtual Machines are managed by Innoactive
            Portal.
          </Text>
          <Spacer />
          <Popover>
            <PopoverTrigger>
              <Button>
                Filter
                <Icon as={FilterIcon} ml={3} boxSize={3} />
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverArrow />
                <PopoverHeader>Filter</PopoverHeader>
                <PopoverCloseButton />
                <PopoverBody>
                  <Switch
                    isChecked={includeDestroyed}
                    onChange={setIncludeDestroyed.toggle}
                  >
                    Include destroyed
                  </Switch>
                </PopoverBody>
                <PopoverFooter textAlign="right">
                  <Link onClick={() => setIncludeDestroyed.off()}>Clear</Link>
                </PopoverFooter>
              </PopoverContent>
            </Portal>
          </Popover>
          <Button
            colorScheme="brand"
            onClick={() => createVirtualMachineDisclosure.onOpen()}
            flexShrink={0}
          >
            Create Virtual Machine
          </Button>
        </HStack>
        {virtualMachinesQuery.data &&
        !virtualMachinesQuery.data.items.length ? (
          <NoData text="Looks like there are no virtual machines (yet)." />
        ) : (
          <PaginatedTable table={table} />
        )}
      </Stack>
      <CreateVirtualMachineModal {...createVirtualMachineDisclosure} />
    </>
  );
};
