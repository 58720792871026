import {
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Spinner,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useVmSizesQuery } from "../../hooks";
import { useCloudRenderingRegionMutation } from "../hooks/useCloudRenderingRegionMutation";
import { useCloudRenderingRegionQuery } from "../hooks/useCloudRenderingRegionsQuery";
import { useFixedIps } from "../hooks/useFixedIps";
import { UnmanagedNodesOverview } from "./UnmanagedNodesOverview";

export const GeneralTab = ({ region }: { region: string }) => {
  const regionDetailsQuery = useCloudRenderingRegionQuery(region);
  const toast = useToast();
  const { data: vmSizes } = useVmSizesQuery();
  const fixedIps = useFixedIps();

  const regionsMutation = useCloudRenderingRegionMutation({
    onSuccess: (data, variables) => {
      const disabledAndFixedIps =
        !variables.enabled &&
        fixedIps.data?.some((ip) => ip.region === variables.name);
      toast({
        title: variables.enabled ? "Enabled region" : "Disabled region",
        description: variables.enabled
          ? `Enabled ${variables.displayName} region.`
          : disabledAndFixedIps
            ? `Disabled ${variables.displayName} region. Fixed IP's must be deleted manually.`
            : `Disabled ${variables.displayName} region.`,
        status: disabledAndFixedIps ? "warning" : "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    },
    onError: (data, variables) => {
      toast({
        title: "Error",
        description: `Could not save ${variables.displayName} update.`,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    },
  });

  const enabledAvailableVmSizes = useMemo(() => {
    return vmSizes?.filter(
      (vm) =>
        vm.isEnabled &&
        regionDetailsQuery?.data?.supportedVmSizes.includes(vm.name),
    );
  }, [regionDetailsQuery?.data?.supportedVmSizes, vmSizes]);

  return (
    <Stack>
      <FormControl>
        <FormLabel>Enabled</FormLabel>
        <Stack direction="row" align="center">
          <Switch
            isChecked={regionDetailsQuery?.data?.isEnabled}
            isDisabled={regionsMutation.isPending}
            onChange={async (change) => {
              regionsMutation.mutateAsync({
                name: region,
                displayName: regionDetailsQuery?.data?.displayName ?? "",
                enabled: change.target.checked,
              });
            }}
          />
          <Spinner
            opacity={
              regionsMutation.isPending &&
              regionsMutation.variables?.name === region
                ? 1
                : 0
            }
          />
        </Stack>
        <FormHelperText>
          You can enable/disable this region. If a region is disabled, you
          cannot use it for cloud rendering.
        </FormHelperText>
      </FormControl>
      <Heading pt={4} size="sm">
        Available VM&apos;s
      </Heading>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Min Ram</Th>
              <Th>Min CPU</Th>
            </Tr>
          </Thead>
          <Tbody>
            {enabledAvailableVmSizes?.map((size, idx) => {
              return (
                <Tr key={idx}>
                  <Td>{size?.displayName}</Td>
                  <Td>&gt;= {size?.minRam}</Td>
                  <Td>&gt;={size?.minVCpu}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {regionDetailsQuery?.data?.type === "Unmanaged" && (
        <>
          <Heading pt={4} size="sm">
            Rendering Nodes
          </Heading>
          <UnmanagedNodesOverview region={region} />
        </>
      )}
    </Stack>
  );
};
