import { HStack, Link, Text, Tooltip } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { FaRegPlayCircle as SessionActiveIcon } from "react-icons/fa";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { Application, User } from "../backend";
import {
  DateTimeFormatted,
  LazyUserProfile,
  SessionStatus,
  TextWithCopyToClipboard,
} from "../components";
import { LazyApplicationDisplay } from "../components/ApplicationDisplay";
import { MotionIcon } from "../components/MotionIcon";
import { SessionRenderingVisualization } from "../components/SessionRenderingVisualization";
import { namedRoutes } from "../routes";
import { Session } from "../session-management";
import {
  getRenderingType,
  getSessionDuration,
} from "../session-management/utils";
import { DeviceTypeVisualization } from "./DeviceTypeVisualization";

const columnHelper = createColumnHelper<
  Session & Partial<{ app: Application; user: User }>
>();

export const userColumn = columnHelper.accessor("userIdentifier", {
  id: "user",
  header: "User",
  cell: (info) =>
    info.getValue() ? (
      <RouterLink
        to={generatePath(namedRoutes.user.overview, {
          userId: info.getValue().toString(),
        })}
      >
        <LazyUserProfile userId={info.getValue()} />
      </RouterLink>
    ) : (
      `n/a (ID: ${info.row.original.userIdentifier})`
    ),
  enableSorting: false,
});

export const durationStatusColumn = columnHelper.accessor(
  (session) => session,
  {
    id: "duration",
    header: "Duration",
    cell: (info) => {
      const session = info.getValue();
      const sessionDuration = getSessionDuration(session);
      if (!sessionDuration && session.sessionTerminatedDateTime) {
        return <SessionStatus session={info.getValue()} />;
      }
      const textDuration = dayjs.duration(sessionDuration).humanize();
      const sessionNotEnded = !session.sessionTerminatedDateTime;
      const sessionIsStillLoading =
        !session.experienceStartedDateTime && sessionNotEnded;

      // session still active?
      if (sessionNotEnded) {
        return (
          <Tooltip
            label={
              sessionIsStillLoading
                ? "Session loading ..."
                : `Session running since ${textDuration}`
            }
          >
            <HStack>
              <MotionIcon
                initial={{ opacity: 1 }}
                animate={{ opacity: 0.4 }}
                transition={{
                  repeat: Infinity,
                  repeatType: "reverse",
                  duration: 1,
                }}
                color="red.400"
                as={SessionActiveIcon}
              />{" "}
              <Text>
                {sessionIsStillLoading ? "Loading ..." : textDuration}
              </Text>
            </HStack>
          </Tooltip>
        );
      }
      return textDuration;
    },
    sortingFn: (r1, r2) =>
      getSessionDuration(r1.original) - getSessionDuration(r2.original),
  },
);

export const applicationColumn = columnHelper.accessor(
  (session) => session.appId,
  {
    id: "application",
    header: "Application",
    cell: (props) => {
      const appId = props.getValue();

      if (!appId) return null;

      return (
        <Link
          as={RouterLink}
          to={generatePath(namedRoutes.application.overview, {
            applicationId: appId,
          })}
          textDecoration={"none"}
          _hover={{ textDecoration: "none", cursor: "pointer" }}
          role="group"
        >
          <LazyApplicationDisplay applicationId={appId} />
        </Link>
      );
    },
    enableSorting: false,
  },
);

export const columns = [
  applicationColumn,
  columnHelper.accessor("id", {
    id: "id",
    header: "ID",
    cell: (info) => (
      <TextWithCopyToClipboard
        label={info.getValue().substring(0, 7) + "..."}
        value={info.getValue().toString()}
        w={14}
      />
    ),
  }),
  columnHelper.display({
    id: "status",
    header: "Status",
    cell: (info) => {
      return <SessionStatus session={info.row.original} />;
    },
  }),
  columnHelper.accessor(
    (session) => {
      let date = session.experienceStartedDateTime;
      if (!date) {
        date = session.sessionTerminatedDateTime;
        if (!date) return undefined;
      }
      return new Date(date);
    },
    {
      id: "startTime",
      header: "Time",
      cell: (info) => {
        const date = info.getValue();
        if (!date) return null;
        return <DateTimeFormatted date={date} />;
      },
      sortingFn: "datetime",
      sortUndefined: 1,
    },
  ),
  durationStatusColumn,
  columnHelper.accessor((session) => session, {
    id: "renderType",
    header: "Rendering",
    cell: (info) => <SessionRenderingVisualization session={info.getValue()} />,
    sortingFn: (r1, r2) =>
      getRenderingType(r1.original).localeCompare(
        getRenderingType(r2.original),
      ),
  }),
  columnHelper.accessor("deviceType", {
    header: "Device",
    cell(props) {
      return <DeviceTypeVisualization deviceType={props.getValue()} />;
    },
  }),
  userColumn,
];
