import {
  GridItem,
  HStack,
  Heading,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { MdLocationPin as LocationIcon } from "react-icons/md";
import { useCloudRenderingRegionQuery } from "../cloud-rendering/hooks/useCloudRenderingRegionsQuery";
import { useVmSizeQuery } from "../cloud-rendering/hooks/useVmSizesQuery";
import { Session } from "../session-management";
import { getRenderingType } from "../session-management/utils";
import { CloudRenderingRegionTypeDisplay } from "./";
import { CloudProviderDisplay } from "./CloudProviderDisplay";
import { RegionDisplay } from "./RegionDisplay";
import { AndroidIcon, CloudRenderingIcon, DesktopClientIcon } from "./icons";

export function RemoteRenderingRegionVisualization({
  regionName,
  vmSize,
}: {
  regionName: string | null;
  vmSize?: string | null;
}) {
  const regionQuery = useCloudRenderingRegionQuery(regionName);

  if (regionQuery.isLoading) {
    return <Skeleton isLoaded={regionQuery.isSuccess}>Loading...</Skeleton>;
  }
  if (regionQuery.isError || !regionQuery.data) {
    return <Text>Unknown</Text>;
  }

  const { cloudProvider, type } = regionQuery.data;

  return (
    <Popover trigger="hover" placement="left">
      <Text>
        <PopoverTrigger>
          <HStack>
            <Text
              as="span"
              borderBottomWidth={2}
              borderStyle="dotted"
              cursor={"pointer"}
              _hover={{
                borderColor: "brand.100",
              }}
            >
              <RegionDisplay region={regionName} />
            </Text>
          </HStack>
        </PopoverTrigger>
        <PopoverContent width="sm">
          <PopoverArrow />
          <PopoverBody>
            <SimpleGrid columns={2} gap={2}>
              <GridItem>
                <Stack>
                  <Heading size="2xs" as="h4">
                    Location
                  </Heading>
                  <HStack>
                    <Icon as={LocationIcon} />
                    <Text whiteSpace={"normal"}>
                      <RegionDisplay region={regionName} />
                    </Text>
                  </HStack>
                </Stack>
              </GridItem>
              <GridItem>
                <Stack>
                  <Heading size="2xs" as="h4">
                    Provider
                  </Heading>
                  <CloudProviderDisplay cloudProvider={cloudProvider} />
                </Stack>
              </GridItem>
              <GridItem>
                <Stack>
                  <Heading size="2xs" as="h4">
                    Location Type
                  </Heading>
                  <CloudRenderingRegionTypeDisplay type={type ?? "Region"} />
                </Stack>
              </GridItem>
              {vmSize && (
                <GridItem>
                  <Stack>
                    <Heading size="2xs" as="h4">
                      Performance Tier
                    </Heading>
                    <VmSizeVisualization vmSize={vmSize} />
                  </Stack>
                </GridItem>
              )}
            </SimpleGrid>
          </PopoverBody>
        </PopoverContent>
      </Text>
    </Popover>
  );
}

export function SessionRenderingVisualization({
  session,
}: {
  session: Pick<
    Session,
    "appTargetPlatform" | "deviceType" | "renderRegion" | "vmSize"
  >;
}) {
  const renderingType = useMemo(() => getRenderingType(session), [session]);

  if (renderingType === "cloud") {
    return (
      <HStack>
        <Icon as={CloudRenderingIcon} />
        <RemoteRenderingRegionVisualization
          regionName={session.renderRegion}
          vmSize={session.vmSize}
        />
      </HStack>
    );
  }

  return (
    <HStack>
      <Icon
        as={
          session.appTargetPlatform === "Windows"
            ? DesktopClientIcon
            : AndroidIcon
        }
      />
      <Text>Local</Text>
    </HStack>
  );
}

function VmSizeVisualization({ vmSize }: { vmSize: string }) {
  const vmSizeQuery = useVmSizeQuery(vmSize);

  if (vmSizeQuery.isLoading) {
    return <Skeleton isLoaded={vmSizeQuery.isSuccess}>Loading...</Skeleton>;
  }

  if (vmSizeQuery.isError || !vmSizeQuery.data) {
    return <Text>{vmSize}</Text>;
  }

  return (
    <Text>
      {vmSizeQuery.data.displayName} ({vmSizeQuery.data.graphicsCardDisplayName}
      )
    </Text>
  );
}
