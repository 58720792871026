import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useActiveOrganizationQuery } from "../../hooks";
import {
  Region,
  RegionFilter,
  getCloudRenderingRegions,
} from "../../session-management";

export function getCloudRegionsQueryKey(filters: RegionFilter = {}) {
  return ["regions", filters];
}

export function useCloudRenderingRegionsQuery<T = Region[]>(
  filters: RegionFilter = {
    organizationId: undefined,
    includeEmptyVmSizes: true,
  },
  options: Partial<UseQueryOptions<Region[], Error, T>> = {},
) {
  const { data: organization } = useActiveOrganizationQuery();

  // if not set explicitly
  if (filters.organizationId === undefined) {
    filters.organizationId = organization?.id?.toString();
  }

  return useQuery({
    queryKey: getCloudRegionsQueryKey(filters),
    queryFn: () => getCloudRenderingRegions(filters),
    refetchOnMount: false,
    staleTime: 1800,
    enabled: !!organization,
    retry: false,
    throwOnError: true,
    ...options,
  });
}

export function useCloudRenderingRegionQuery(name: string | null) {
  return useCloudRenderingRegionsQuery(
    {
      includeDisabledRegions: true,
    },
    {
      select: (regions) => regions?.find((x) => x.name === name),
    },
  );
}
