import { Box, HStack, Heading, Stack, Text } from "@chakra-ui/react";
import { getLocalTimeZone } from "@internationalized/date";
import dayjs from "dayjs";
import { useMemo } from "react";
import { SessionsTable } from "../components/SessionsTable";
import { DateRangePicker } from "../components/date-range-picker";
import { useDateRange } from "../hooks/useDateRange";
import { SessionListContextProvider } from "./SessionListContextProvider";
import { columns as sessionColumns } from "./table-columns";

const columns = sessionColumns
  // remove status column
  .filter((col) => col.id !== "status")
  // move application column to the front
  .sort((a) => (a.id === "application" ? -1 : 0));

export function SessionsList() {
  const {
    startDate,
    endDate,
    updateDateRange,
    defaultDateRange,
    minDate,
    maxDate,
  } = useDateRange({
    fromDate: useMemo(
      () => dayjs().subtract(7, "days").startOf("day").toDate(),
      [],
    ),
    toDate: useMemo(() => dayjs().endOf("day").toDate(), []),
  });

  return (
    <Stack spacing={6}>
      <Box>
        <Heading size="lg">Sessions</Heading>
        <Text>
          A detailed list of sessions of users within your organization.
        </Text>
      </Box>
      <SessionListContextProvider from={startDate} to={endDate}>
        <HStack>
          <Text whiteSpace={"nowrap"}>Showing data for timespan</Text>
          <DateRangePicker
            defaultValue={defaultDateRange}
            minValue={minDate}
            maxValue={maxDate}
            onChange={updateDateRange}
            label="Pick a date range for the report"
          />
          <Text fontSize="sm" whiteSpace={"nowrap"} color="chakra-subtle-text">
            (Timezone: &quot;{getLocalTimeZone()}&quot;)
          </Text>
        </HStack>
        <SessionsTable columns={columns} />
      </SessionListContextProvider>
    </Stack>
  );
}
