import { Text } from "@chakra-ui/react";
import { Session } from "../session-management";

export function DeviceTypeVisualization({
  deviceType,
}: {
  deviceType: Session["deviceType"];
}) {
  return (
    <Text>
      {deviceType === "PcDesktopClient"
        ? "Windows PC"
        : deviceType === "Browser"
          ? "Browser"
          : deviceType === "VrStandalone"
            ? "XR Headset"
            : "Unknown"}
    </Text>
  );
}
