import {
  Avatar,
  Box,
  forwardRef,
  HStack,
  Skeleton,
  StackProps,
  Text,
  TextProps,
} from "@chakra-ui/react";
import { User } from "../backend";
import { useUserQuery } from "../hooks";

type UserProfileProps = {
  user?: Pick<User, "full_name" | "email">;
  align?: "left" | "right";
  textProps?: TextProps;
} & StackProps;

export const UserProfile = forwardRef(
  ({ user, align = "left", textProps, ...props }: UserProfileProps, ref) => {
    return (
      <HStack
        ref={ref}
        flexDirection={align === "right" ? "row-reverse" : "row"}
        role="group"
        {...props}
      >
        <Skeleton
          isLoaded={!!user}
          paddingLeft={align === "right" ? 1 : undefined}
        >
          <Avatar name={user?.full_name} size="sm" _groupHover={undefined} />
        </Skeleton>
        <Box textAlign={align} {...textProps}>
          <Skeleton isLoaded={!!user}>
            <Text fontSize="sm" fontWeight="medium">
              {user?.full_name || "Loading"}
            </Text>
          </Skeleton>
          {(!user || user?.email !== user?.full_name) && (
            <Skeleton isLoaded={!!user}>
              <Text fontSize="xs" lineHeight="shorter" color="menu-text">
                {user?.email || "example@example.com"}
              </Text>
            </Skeleton>
          )}
        </Box>
      </HStack>
    );
  },
);

export const UserProfileSkeleton = forwardRef(
  (props: Omit<UserProfileProps, "user">, ref) => (
    <UserProfile {...props} ref={ref} />
  ),
);

export function LazyUserProfile({ userId }: { userId: string | number }) {
  const userQuery = useUserQuery({
    userId,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  });

  if (userQuery.isError) {
    return <UnknownUserProfile />;
  }

  return <UserProfile user={userQuery.data} />;
}

export function UnknownUserProfile() {
  return <UserProfile user={{ full_name: "Unknown User", email: "---" }} />;
}
